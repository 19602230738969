<template>
  <div class="text-area">
    <p v-if="title" class="text-area-title">{{ title }}</p>
    <div class="text-area-content" :class="{ disabled }">
      <textarea
        id=""
        ref="textinput"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled || loading"
        name=""
        class="text-area-input"
        :class="{ [size]: true }"
        :style="textareaStyle"
        v-bind="{
          ...(maxLength && { maxlength: maxLength })
        }"
        @input="handleInput"
      />
      <div
        v-if="maxLength || buttonText || buttonIcon"
        class="text-area-footer"
      >
        <div v-if="maxLength" class="text-area-footer-count">
          <span>{{ value.length }}</span>
          <span> / {{ maxLength }}</span>
        </div>
        <Button
          v-if="hasCancelButton"
          type="white"
          text="Cancel"
          size="xs"
          :disabled="disabled || loading"
          @click="$emit('cancel')"
        />
        <Button
          v-if="buttonText || buttonIcon"
          :icon="buttonIcon"
          :text="buttonText"
          size="xs"
          :disabled="!value || disabled"
          :loading="loading"
          @click="handleButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@c/library/Button.vue'

export default {
  name: 'TextArea',
  components: {
    Button
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 's',
      validator: (value) => ['s', 'm', 'l'].includes(value)
    },
    buttonText: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    hasCancelButton: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitWithButton: {
      type: Boolean,
      default: true
    },
    maxHeight: {
      type: String,
      default: ''
    },
    minHeight: {
      type: String,
      default: ''
    }
  },
  computed: {
    textareaStyle() {
      return {
        ...(this.maxHeight ? { maxHeight: `${this.maxHeight}` } : {}),
        ...(this.minHeight ? { minHeight: `${this.minHeight}` } : {})
      }
    }
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.adjustHeightToContent()
      })
    }
  },
  mounted() {
    this.adjustHeightToContent()
  },
  methods: {
    adjustHeightToContent() {
      const textarea = this.$refs.textinput
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight + 10}px`
    },
    handleInput() {
      this.$emit('input', this.$refs.textinput.value)
      this.adjustHeightToContent()
    },
    handleButtonClick() {
      this.$emit(this.submitWithButton ? 'submit' : 'button-click', this.value)
    }
  }
}
</script>

<style scoped lang="scss">
.text-area {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;

  &-input {
    width: 100%;
    background: #f1f2f3;
    border: 1px solid #e9ebed;
    outline: none;
    resize: vertical;
    display: block;
    border-radius: 4px;

    &.rounded {
      border-radius: 16px;

      &:only-child {
        border-radius: 16px;
      }

      &:not(:only-child) {
        border-radius: 16px 16px 0 0;
      }
    }

    &.s {
      padding: 0.5rem 0.85rem;
    }

    &.m {
      padding: 0.75rem 0.85rem;
    }

    &.l {
      padding: 1rem;
    }

    &:focus,
    &:focus-visible,
    &:focus-within {
      border: 1px solid $primary;
      background: white;
    }

    &::placeholder {
      color: #8f9399;
    }

    &:only-child {
      border-radius: 4px;
    }

    &:not(:only-child) {
      border-radius: 4px 4px 0 0;
    }
  }

  &-title {
    font-weight: 600;
    color: #303032;
  }

  &-content {
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 0 0 4px 4px;
    border: 1px solid #e9ebed;
    border-top: none;
    background: white;

    &-count {
      color: rgba(#000, 32%);
      font-size: 0.8rem;
      user-select: none;
    }
  }
}
</style>
