var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-area" }, [
    _vm.title
      ? _c("p", { staticClass: "text-area-title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "text-area-content", class: { disabled: _vm.disabled } },
      [
        _c(
          "textarea",
          _vm._b(
            {
              ref: "textinput",
              staticClass: "text-area-input",
              class: ((_obj = {}), (_obj[_vm.size] = true), _obj),
              style: _vm.textareaStyle,
              attrs: {
                id: "",
                placeholder: _vm.placeholder,
                disabled: _vm.disabled || _vm.loading,
                name: ""
              },
              domProps: { value: _vm.value },
              on: { input: _vm.handleInput }
            },
            "textarea",
            Object.assign({}, _vm.maxLength && { maxlength: _vm.maxLength }),
            false
          )
        ),
        _vm._v(" "),
        _vm.maxLength || _vm.buttonText || _vm.buttonIcon
          ? _c(
              "div",
              { staticClass: "text-area-footer" },
              [
                _vm.maxLength
                  ? _c("div", { staticClass: "text-area-footer-count" }, [
                      _c("span", [_vm._v(_vm._s(_vm.value.length))]),
                      _c("span", [_vm._v(" / " + _vm._s(_vm.maxLength))])
                    ])
                  : _vm._e(),
                _vm.hasCancelButton
                  ? _c("Button", {
                      attrs: {
                        type: "white",
                        text: "Cancel",
                        size: "xs",
                        disabled: _vm.disabled || _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel")
                        }
                      }
                    })
                  : _vm._e(),
                _vm.buttonText || _vm.buttonIcon
                  ? _c("Button", {
                      attrs: {
                        icon: _vm.buttonIcon,
                        text: _vm.buttonText,
                        size: "xs",
                        disabled: !_vm.value || _vm.disabled,
                        loading: _vm.loading
                      },
                      on: { click: _vm.handleButtonClick }
                    })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }