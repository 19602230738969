var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search", class: { expanded: _vm.query && !_vm.blurred } },
    [
      _c(
        "div",
        { ref: "searchinputcontainer" },
        [
          _c("TextInput", {
            key: _vm.inputKey,
            ref: "searchinput",
            staticClass: "search-input",
            attrs: {
              placeholder: _vm.placeholder,
              "left-icon": "search",
              "button-icon": _vm.query ? "close" : "",
              "submit-with-button": false,
              title: _vm.title,
              focused: !!_vm.query
            },
            on: {
              "button-click": function($event) {
                _vm.query = ""
              },
              focus: _vm.focusSearchInput,
              blur: function() {
                return (_vm.searchInputFocused = false)
              }
            },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          })
        ],
        1
      ),
      _vm.query && !_vm.blurred
        ? _c(
            "div",
            {
              staticClass: "search-suggest",
              class: { "is-fixed": _vm.positionFixed },
              style: Object.assign(
                {},
                _vm.positionFixed ? _vm.calculatedPosition : {}
              )
            },
            [
              !_vm.searchResultsLoading && _vm.searchResults.length
                ? _c(
                    "div",
                    { staticClass: "search-suggest-items" },
                    _vm._l(_vm.searchResults, function(item, idx) {
                      return _c(
                        "div",
                        {
                          key: item.name,
                          staticClass: "search-suggest-item",
                          class: {
                            focused: idx === _vm.searchSelectionFocused
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleResultSelect(item)
                            }
                          }
                        },
                        [
                          _vm._t(
                            "item",
                            function() {
                              return [
                                _vm.image(item)
                                  ? _c("img", {
                                      staticClass: "search-suggest-item-image",
                                      attrs: { src: _vm.image(item), alt: "" }
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "search-suggest-item-info" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "search-suggest-item-name"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.name(item)) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "search-suggest-item-summary"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.summary(item)) + " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm.showCollect
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "search-suggest-item-collect"
                                      },
                                      [
                                        _c("Button", {
                                          attrs: {
                                            icon: "plus-medium",
                                            type: "white"
                                          },
                                          nativeOn: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.handleResultSelect(
                                                item
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            },
                            {
                              item: item,
                              index: idx,
                              select: _vm.handleResultSelect
                            }
                          )
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm.searchResultsLoading
                ? _c("div", { staticClass: "search-suggest-status" }, [
                    _c("img", {
                      staticClass: "search-suggest-status-icon spinning",
                      attrs: {
                        src: require("@/assets/icons/spinner.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.loadingText) + " ")
                  ])
                : !_vm.searchResults || !_vm.searchResults.length
                ? _c("div", { staticClass: "search-suggest-status" }, [
                    _c("img", {
                      staticClass: "search-suggest-status-icon",
                      attrs: {
                        src: require("@/assets/icons/search_frame.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" No results found ")
                  ])
                : _vm._e(),
              _vm.showLast
                ? _c(
                    "div",
                    {
                      staticClass: "search-suggest-item",
                      on: {
                        click: function($event) {
                          return _vm.$emit("lastClicked")
                        }
                      }
                    },
                    [_vm._t("last", null, { query: _vm.query })],
                    2
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }